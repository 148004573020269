module.exports = [{
      plugin: require('../../../common/temp/node_modules/.pnpm/gatsby-remark-images@7.14.0_gatsby-plugin-sharp@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_esli_5dqjrgg5mgadfsigf7s47kdn6e/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"markdownCaptions":true,"withWebp":true,"showCaptions":["title"],"maxWidth":650,"linkImagesToOriginal":true,"backgroundColor":"white","quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../common/temp/node_modules/.pnpm/gatsby-plugin-intl@5.10.0_react@18.3.1_typescript@5.7.3/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en"],"fallbackLanguage":"en","defaultLanguage":"en","path":"/opt/atlassian/pipelines/agent/build/sites/site-v1/locales/","redirect":true,"redirectDefaultLanguageToRoot":true},
    },{
      plugin: require('../../../common/temp/node_modules/.pnpm/gatsby-plugin-catch-links@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.19.0_jiti@1.21.6__hbvd3etrflhmbordzzqz6gwnma/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../plugins/source-traffit/gatsby-browser.js'),
      options: {"plugins":[],"traffitAccountName":"cloudtechconsulting"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../common/temp/node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.19.0_jiti@1.21.6___react-dom@18.3.1_react@18.3.1___ctwgye4dv3hghvggpgslzt36ci/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
